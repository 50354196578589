export const recipe_details = [
    {
        "name": "Thai Lentil Soup",
        "recipe": "thai-lentil-soup",
        "subtitle": "none",
        "date": "07/January/23",
        "reviewer": {name:"Zoe Anderson",twitter:"https://www.instagram.com/sophieheronfield/"},
        "wifereview": "",
        "kidsreview": "",
        "image_1": {image: "lentilSoup.jpg", text: ""},
        "image_4": "",
        "image_2": "",
        "image_3": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Another vegetarian recipe from Zoe - an amazing soup for cold Januarys!",
        "paragraph_2":"1. Chop all the vegetables.",
        "paragraph_3":"2. Put them and all the other ingredients in a pot.",
        "paragraph_4":"3. Top up the pot with about 500ml stock.",
        "paragraph_5":"4. Simmer for 30 minutes.",
        "paragraph_6":"5. When done, blend.",
        "paragraph_7":"6. Serve with lime juice and chopped parsley or coriander.",
        "paragraph_8":"7. Enjoy!",
        "paragraph_9":"",
        "paragraph_10":"",
        "ingredients":["Half an onion" ,"One carrot", "One stick celery", "One pepper","80g lentils" ,"Tin of coconut milk" ,"Big squirt of tomato puree" ,
        "Thai curry paste - I used the wee green curry one that comes in a small pot it's like a block rather than a paste - or use 2 tbsp of paste if you have it."],
    },
    {
        "name": "Hoisin Duck Pizza",
        "recipe": "hoisin-duck-pizza",
        "subtitle": "none",
        "date": "8/Sep/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "",
        "kidsreview": "",
        "image_1": {image: "pizza1.jpg", text: "",alt:"Pizza on plate with salad leaves and tomatoes."},
        "image_2": "",
        "image_4": {image: "pizza2.jpg", text: "",alt:"Uncooked pizza on wooden board."},
        "image_3": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"A different and delicious pizza!",
        "paragraph_2":"1. Cook the duck breast for about 20 mins in an oven dish, in the oven - about 175 (fan-assisted). No need to cover it.",
        "paragraph_3":"2. While the duck is cooking, place the pizza base somewhere flat and put on a few large spoonfuls of hoisin sauce. Spread it out around the base.",
        "paragraph_5":"3. Sprinkle the mozzarella cheese over the base.",
        "paragraph_6":"4. Finely chop the spring onion and sprinkle over the base.",
        "paragraph_7":"5. Roughly chop the cashews and sprinkle them on top aswell.",
        "paragraph_8":"6. When the duck is done, take it out and carefully (it'll be hot!) cut it into small pieces - sprinkle these on top.",
        "paragraph_9":"7. Put the pizza in the oven - it should only take about 8 mins, so keep an eye on it.",
        "paragraph_10":"8. Take out, cut, and enjoy!",
        "paragraph_11":"Serving suggestion - add some nice salad on the side - lettuce, cucumber, tomato, balsamic vinegar.",
        "ingredients":["Pizza base - make your own if you know how, or get one from the supermarket!","One duck breast.","Hoisin sauce (from a bottle is fine)."
        ,"Grated mozzerella.","A couple of spring onions.","Handful of cashews."],
    },
    {
        "name": "Creamy Mushroom and Kale Pasta",
        "recipe": "creamy-mushroom-and-kale-pasta",
        "subtitle": "none",
        "date": "30/August/22",
        "reviewer": {name:"Zoe Anderson",twitter:"https://www.instagram.com/sophieheronfield/"},
        "wifereview": "",
        "kidsreview": "",
        "image_1": {image: "creamyMushroom.jpg", text: ""},
        "image_4": "",
        "image_2": "",
        "image_3": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"A creamy and easy pasta!",
        "paragraph_2":"1. Cook the past as per instructions and drain, saving a splash of the cooking water.",
        "paragraph_3":"2. Meanwhile, finely chop the onion and roughly chop the mushroom.",
        "paragraph_5":"3. Fry in the oil until mushrooms start to brown.",
        "paragraph_6":"4. Chop the kale and broccoli and add to the pan, discarding any tough stems. ",
        "paragraph_7":"5. Continue to cook until the kale is starting to wilt then add the creme fraiche along with a little of the pasta cooking water.",
        "paragraph_8":"6. Stir well then mix with the pasta.",
        "paragraph_9":"7. Season and add some chilli flakes too if you like - I do!",
        "paragraph_10":"",
        "ingredients":["160g pasta of your choice","2 tsp garlic and rosemary oil (or use plain oil and add some garlic)"
        , "Half a red onion", "100g mushrooms", "Five or six big leaves of kale", "Few small stems of broccoli",
        "100g Oatly creme fraiche (or whatever you prefer)"],
    },
    {
        "name": "Broccoli and Pak Choi Noodles",
        "recipe": "broccoli-pak-choi-noodles",
        "subtitle": "none",
        "date": "10/August/22",
        "reviewer": {name:"Zoe Anderson",twitter:"https://www.instagram.com/sophieheronfield/"},
        "wifereview": "",
        "kidsreview": "",
        "image_1": {image: "noodles1.jpg", text: ""},
        "image_4": {image: "noodles3.jpg", text: "Nice"},
        "image_2": "",
        "image_3": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Another simple but amazing vegetarian recipe from Zoe Anderson!",
        "paragraph_2":"1. Chop the veg into bite size pieces.",
        "paragraph_3":"2. Add to a saucepan with 500ml veg stock.",
        "paragraph_5":"3. Bring to the boil and add your noodles – I like to break them up a bit to allow for easier eating!",
        "paragraph_6":"4. Simmer for about five mins, then turn off the heat and leave to sit and soak up the stock, adding more liquid if you like.",
        "paragraph_7":"5. At this point you could add some Indian or Thai curry paste, but I like to add a few drops from this little bottle of awesomesauce! It’s chilli sesame oil and available in Asian supermarkets or on Amazon.",
        "paragraph_8":"6. Enjoy!",
        "paragraph_9":"",
        "paragraph_10":"",
        "ingredients":["Half a head of broccoli","Two heads of pak choi","80g dried noodles (I like the udon or ramen ones)"
            ,"Vegetable stock","Chilli oil"],
    },
    {
        "name": "Marshmallow Top Hats",
        "recipe": "marshmallow-top-hats",
        "subtitle": "none",
        "date": "23/Jul/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "",
        "kidsreview": "",
        "image_1": {image: "tophat1.jpg", text: "Impossible to only have one"},
        "image_3": {image: "tophat2.jpg", text: "So simple"},
        "image_2": "",
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"One of of our most simplest recipes - marshmallow top hats, suitable for any occasion!",
        "paragraph_2":"1. Melt the milk chocolate - we do this in a bowl, over gently simmering hot water. Be careful not to burn yourself" +
            " on the water, steam, bowl or pot!",
        "paragraph_3":"2. When melted, take off the heat, then gently dip a marshmallow top first into the delicous chocolate.",
        "paragraph_5":"3. Place on plate, and then gently put a smartie on top!",
        "paragraph_6":"4. Repeat for all marshmallows, then put plate in fridge for a short while to set.",
        "paragraph_7":"5. Enjoy!",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "ingredients":["Bag of Marshmallows","Milk chocolate to melt","Smarties"],
    },
    {
        "name": "Mexican Bean Salad",
        "recipe": "mexican-bean-salad",
        "subtitle": "none",
        "date": "18/Jul/22",
        "reviewer": {name:"Zoe Anderson",twitter:"https://www.instagram.com/sophieheronfield/"},
        "wifereview": "",
        "kidsreview": "",
        "image_1": {image: "salad2.jpeg", text: "Colourful and delicious"},
        "image_4": {image: "salad1.jpeg", text: "Yes please"},
        "image_2": "",
        "image_3": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"This is a really versatile base salad you can do a lot with – as a main, a side or a snack.",
        "paragraph_2":"1. Chop the peppers and scallions into bean-sized pieces.",
        "paragraph_3":"2. Halve or quarter the tomatoes and finely chop the parsley (you could use coriander if you’re one of those people!).",
        "paragraph_5":"3. Drain the beans and corn.",
        "paragraph_6":"4. Mix all together in a large bowl with the oil and lime juice, season with salt and pepper.",
        "paragraph_7":"",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "ingredients":["Two or three peppers","Three scallions","Half a punnet of cherry tomatoes","Half a pack of flat leaf parsley",
        "Two tins of beans – ideally one kidney, one cannellini or butter (but any beans will do)","One small tin of sweetcorn"
        ,"Tbsp chilli oil","Tbsp lime juice"],
    },
    {
        "name": "Nachos Cheese Sauce",
        "recipe": "nachos-cheese-sauce",
        "subtitle": "none",
        "date": "15/Jan/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "Maybe next time add some salsa and guacamole?",
        "kidsreview": "Even the three year old likes it.",
        "image_1": {image: "nachos.jpg", text: "Nachos with cheese and bacon"},
        "image_2": "",
        "image_3": "",
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Making cheese sauce for nachos is easier than you may think and is a family favourite around here.",
        "paragraph_2":"Melt the butter in a saucepan, adding the flour as it does. Mix thoroughly - the butter and flour will mix and make" +
        " a kind of dry mixture. Add the milk and keep stirring on a medium heat - don't let it boil. This will take a few minutes, but " +
        " what you're waiting for is for the sauce to get thicker.",
        "paragraph_3":"Take off the heat and stir in the cheese, until it's all melted and you've a lovely smooth cheese sauce.",
        "paragraph_5":"Pour over your nachos immediately and enjoy! Careful of course, it'll be hot! ",
        "paragraph_6":"Recommended to go with it - some chopped up and fried bacon.",
        "paragraph_7":"",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "ingredients":["Nachos.","Cup of grated mild white cheddar.","One tablespoons of butter.","Two tablespoons of flour.", "About 200mls milk."],
    },
    {
        "name": "Homemade Bacon & Egg Muffins",
        "recipe": "bacon-and-egg-muffins",
        "subtitle": "none",
        "date": "16/Jan/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "Incredible, just like the McDonalds ones!",
        "kidsreview": "I dropped mine off the bed so I couldn't eat it and it was so sad!",
        "image_1": {image: "muffin.jpg", text: "Ingredients for muffins"},
        "image_2": {image: "muffin1.jpg", text: "It really is this simple"},
        "image_3": {image: "muffin2.jpg", text: "Nom nom"},
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Nothing beats the originals in a certain fast-food restaurant, but we wanted to try make our own!",
        "paragraph_2":"It's really easy - grill some bacon, fry an egg, toast a breakfast muffin, then put them" +
        " all together with a slice of cheese! We use 'easy singles' - not sure what they're called in your" +
        " part of the world, but it's basically the processed cheese that tastes so good on burgers aswell!",
        "paragraph_3":"Add some red sauce for extra favour if you want, then enjoy!",
        "paragraph_5":"",
        "paragraph_6":"",
        "paragraph_7":"",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "ingredients":["Breakfast muffin.","Sliced bacon.","Egg.","Sliced cheese", "Butter (for the toasted muffin)."],
    },
    {
        "name": "How To Cook a Tomahawk Steak",
        "recipe": "how-to-cook-a-tomahawk-steak",
        "subtitle": "As easy as you can get.",
        "date": "11/Jan/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "Amazing",
        "kidsreview": "Can I have some noodles instead please?",
        "image_1": {image: "steak.jpg", text: "We're going to need a bigger pan"},
        "image_2": {image: "tomahawk1.jpg", text: "Simple preparation"},
        "image_3": {image: "tomahawk2.jpg", text: "Got to have the pepper sauce"},
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"A tomahawk steak is a ribeye steak with some bone left on, it's super easy to cook, " +
        "tastes great and one should do two people, maybe!",
        "paragraph_2":"Add some oil to a frying pan and get it really hot. Rub some oil on the steak, season with salt and pepper," +
        " then sear both sides and the edges on the hot pan. This will take a minute or two - you want all the meat a nice brown" +
        " color.",
        "paragraph_3":"Then simply put the frying pan into a preheated oven (Fan 180) and cook to your liking. I usually leave it for" +
        " about 40 minutes - I don't want it too rare or too over-done, but they're usually quite thick" +
        " so will take longer than a piece of sirloin.",
        "paragraph_5":"Once it's done to your liking, take it out, slice it up, and serve on a something relatively fancy! ",
        "paragraph_6":"Recommended to go with it - nice creamy pepper sauce and some fries.",
        "paragraph_7":"",
        "paragraph_8":"",
        "paragraph_9":"",
        "paragraph_10":"",
        "ingredients":["A tomahawk steak","Salt & pepper","Olive or rapeseed oil"],
    },
    ];